<template>
    <div class="create-shop">
        <div class="si-con">
            <a-steps :current="active">
                <a-step title="产品方案"/>
                <a-step title="程序版本"/>
                <a-step title="农场信息"/>
                <a-step title="完成"/>
            </a-steps>
            <!-- 产品方案 -->
            <ul class="si-scheme" v-if="active == 0">
                <li v-for="(item,index) in server" :key="index">
                    <div class="top">
                        <p class="title">{{ item.father_name}}</p>
                        <p class="desc">{{ item.description }}</p>
                    </div>
                    <div class="btm">
                        <p class="desc" v-if="!item.price">可免费使用</p>
                        <p class="desc" v-else>
                            <span> {{item.remark}} </span>
                        </p>
                        <a-button type="primary" @click="selectServer(item.id)">立即创建</a-button>
                    </div>
                </li>
            </ul>

            <!-- 程序版本 -->
            <ul class="si-server" v-if="active == 1">
                <li v-for="(item,index) in meal" :key="index">
                    <p class="title">{{ item.server_name }}</p>
                    <p class="func">{{ item.description }}</p>
                    <div class="price" v-if="item.price>0">{{item.price }}元/年</div>
                    <div class="price" v-else>免费</div>
                    <div class="now-price" v-if="item.discount_price">限时优惠：{{item.discount_price}}元/年</div>
                    <div class="btn">
                        <a-button type="primary" @click="selectMeal(item.server_id)">立即创建</a-button>
                    </div>
                </li>
            </ul>
            <!-- 农场信息 -->
            <div class="si-farm" v-if="active == 2">
                <a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                    <a-form-item label="农场名称">
                        <a-input v-model:value="form.program_name"></a-input>
                    </a-form-item>
                    <a-form-item label="农场logo">
                        <kd-img-select @change="e=>form.logo = e"></kd-img-select>
                    </a-form-item>
                    <a-form-item :wrapper-col="{offset:6}">
                        <a-button type="primary" @click="createShopNow">立即创建</a-button>
                    </a-form-item>
                </a-form>
            </div>

            <div class="si-complete" v-if="active == 3">
                <p>应用创建成功，<span>0{{time}}</span> 秒后自动跳转至应用列表</p>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import appModel from '@/api/saas/app.js'
import setModel from "@/api/saas/set.js"
import serviceModel from "@/api/saas/service.js"
import router from '@/router'
export default {
    setup() {
        const active = ref(0)
        const _d = reactive({
            server:[],
            meal:[],
            config:null,
            form:{
                server_id:0,
                meal_id:0,
                logo:"",
                program_name:"",
                is_trail:0,     //是否为使用版本
            },
            time:5
        })
        setModel.getSiteSet(['app_is_open_try','app_try_days'],(res)=>{
            _d.config = res
        })
        serviceModel.getServerScheme(1,999,res=>_d.server = res.list)
        
        function selectServer(id){
            serviceModel.getBranch(id,1,res=>_d.meal = res)
            active.value = 1
        }

        function selectMeal(server_id){
            _d.form.server_id = server_id
            active.value = 2
        }

        function createShopNow(){
            let param = _d.form

            if( _d.config.app_is_open_try && _d.config.app_try_days ){
                param.is_trail = 1
            }

            _d.meal.forEach(item=>{
                if( item.server_id == param.server_id ){
                    if( item.discount_price == 0 && item.price == 0 ){
                        param.is_trail =0
                    }
                }
            })


            appModel.addOrEditProgram('add',param,()=>{
                active.value = 3
                let timer = setInterval(function(){
                    if( _d.time <=0 ){
                        clearInterval(timer)
                        router.push("/shop/index")
                    }else{
                        _d.time--
                    }
                },1000)
            })
        }

        return{
            active,
            ...toRefs(_d),
            selectServer,
            selectMeal,
            createShopNow
        }
    },
}
</script>
<style lang="scss" scoped>
.bg{
    background: #f0f2f5;
}
.si-con{
    background: #fff;
    padding: 24px;
}
.si-scheme{
    list-style: none;
    margin-top: 24px;

    >li{
        display: inline-block;
        border: 1px solid #f2f2f2;
        width: 180px;
        height: 240px;
        margin: 0 24px 24px 0;
        overflow: hidden;

        .top{
            width: 100%;
            text-align: center;
            background: #f2f2f2;
            min-height: 130px;
            padding-top: 32px;
        }
        .title{
            font-size: 16px;
            font-weight: bold;
        }
        .desc{
            width: 100%;
            padding: 0 12px;
            font-size: 12px;
        }
        .btm{
            width: 100%;
            text-align: center;
            margin-top: 24px;
        }
    }
}

.si-server{
    width: 100%;
    margin-top: 24px;
    text-align: center;

    >li{
        width: 240px;
        height: 320px;
        border: 1px solid #f2f1f1;
        list-style: none;
        display: inline-block;
        margin: 0 24px 24px 0;
        text-align: center;
        padding-top: 24px;
        overflow: hidden;
        position: relative;
    }
    .title{
        font-weight: bold;
        font-size: 20px;
    }
    .desc{
        font-size: 12px;
        color: #666;
    }

    .func{
        margin: 32px 0 48px 0;
        width: 100%;
        height: 56px;
        padding: 0 12px;
    }
    .price{
        font-size: 18px;
        line-height: 24px;
    }
    .now-price{
        padding: 0 8px;
        height: 20px;
        border: 1px solid red;
        color: red;
        font-size: 12px;
        display: inline-block;
    }
    .btn{
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.si-farm{
    margin-top: 24px;
}
.si-complete{
    width: 100%;
    text-align: center;
    height: 300px;
    padding-top: 140px;
    color: #666;

    span{
        font-size: 24px;
        color: #333;
    }
}
</style>